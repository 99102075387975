import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ImageGrid from "../components/ImageGrid";
import ModalContact from "../components/ModalContact";
import PostNav from "../components/PostNav";

// eslint-disable-next-line
export const PortfolioPostTemplate = ({
  content,
  contentComponent,
  description,
  category,
  title,
  helmet,
  year,
  price,
  featuredimage,
  images,
  next,
  previous,
}) => {
  const PostContent = contentComponent || Content;
  const currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <article className="page page--portfolio-post">
      {helmet || ""}
      <div className="container-md">
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="link-category text-uppercase mb-3">
              <Link
                to={`/portfolio/${kebabCase(category)}/`}
                className="icon-arrow text-decoration-none"
              >
                <span className="bi bi-arrow-left-short align-middle"></span>
                {category}
              </Link>
            </div>

            <h1 className="title mb-3">{title}</h1>
            {price && category === 'available' && <p className="price">{currency.format(price)}</p>}
            <PostContent className="content" content={content} />
            {year && category && ( 
              <p className="year">
                { ['archive', 'available'].includes(category) ? `Created in ${year}` : year }
              </p>
            )}
            { category === 'available' && (
              <div className="my-4">
                <ModalContact message={`Regarding ${title}:\n`} className="my-2" />
              </div>
            )}
            <PostNav previous={previous} next={next} category={category} className="post-nav--desktop mt-5 d-none d-md-flex"/>
          </div>
          <div className="col-md-8 col-sm-12">
            {featuredimage && (
              <div className="featured-image-container mb-4">
                <PreviewCompatibleImage imageInfo={featuredimage} objectFit="contain" style={{width: '100%', height: 'auto'}} />
                {/* <Image imageInfo={featuredimage} /> */}
              </div>
            )}
            {images && (
              <div className="images-container mb-4">
                <ImageGrid gridItems={images} />
              </div>
            )}
          </div>
            <PostNav previous={previous} next={next} category={category} className="post-nav--mobile d-md-none my-5 mx-auto" />
        </div>
      </div>
    </article>
  );
};

PortfolioPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const PortfolioPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;
  const { next, previous } = pageContext;
  const title = post.frontmatter.title || null;
  const description = post.frontmatter.description || post.excerpt || null;

  return (
    <Layout>
      <PortfolioPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet>
            {title ? <title>{title}</title> : null}
            {description ? <meta name="description" content={description} /> : null}
            {title ? <meta property="og:title" content={title} /> : null}
          </Helmet>
        }
        featuredimage={post.frontmatter.featuredimage}
        images={post.frontmatter.images}
        category={post.frontmatter.category}
        title={title}
        year={post.frontmatter.year}
        price={post.frontmatter.price}
        next={next}
        previous={previous}
      />
    </Layout>
  );
};

PortfolioPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PortfolioPost;

export const pageQuery = graphql`
  query PortfolioPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        category
        year
        price
        featuredimage {
          alt
          caption
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                height: 500
                quality: 85
                placeholder: BLURRED
                # layout: FULL_WIDTH
                # formats: [AUTO, WEBP]
                # aspectRatio: 1
                backgroundColor: "transparent"
                transformOptions: { fit: OUTSIDE }
                # transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
        images {
          alt
          caption
          image {
            childImageSharp {
              gatsbyImageData(
                # width: null,
                # height: null,
                width: 400,
                height: 400,
                quality: 85,
                placeholder: BLURRED
                # layout: CONSTRAINED
                # aspectRatio: 1
                # backgroundColor: "transparent",
                transformOptions: { fit: OUTSIDE, rotate: 0 }
                # transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
  }
`;
