import React from "react";
import Card from "../components/Card";

function PostNav({ previous, next, category, className, ...rest }) {
  return (
    <div className={`post-nav d-flex flex-wrap justify-content-between ${className}`}>
      {previous && previous.frontmatter.category === category ? (
          <Card
            image={previous.frontmatter.featuredimage || null}
            heading="Previous"
            to={previous.fields.slug}
            data-previous
            rel="prev"
            className="mb-2"
          />
      ) : (
        <></>
      )}
      {next && next.frontmatter.category === category ? (
          <Card
            image={next.frontmatter.featuredimage || null}
            heading="Next"
            to={next.fields.slug}
            data-next
            rel="next"
            className="mb-2"
          />
      ) : (
        <></>
      )}
    </div>
  );
}

export default PostNav;
